import React from 'react';
import { Layout } from '../components/Layout';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import COLORS from '../utils/colors';

const NotFound = () => {
  return (
    <Layout>
      <h1>Page not found</h1>
			<p>The page you are looking for has been removed or relocated.</p>
			<div style={{display: 'flex', justifyContent: 'center', paddingTop: '2em'}}>
				<BackButton type="button" onClick={() => navigate(-1)}>Go back</BackButton>
			</div>
    </Layout>
  );
};

export default NotFound;

const BackButton = styled.button`
	padding: 0.5em 1em;
	font-size: 1em;
	background-color: ${COLORS.blue};
	border: none;
	border-radius: 3px;
	color: ${COLORS.white};
	cursor: pointer;
`;
